import { motion } from "framer-motion"
import {
  fastPageAnimation,
  pageAnimation,
  photoAnim,
  titleAnim,
  scrollReveal
} from "../../../animation"
import Wave from "./wave"
import { useState } from "react";

const Hero = () => {
  const [hoverPosition, setHoverPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    // Calculate the position relative to the center of the image
    const img = e.target;
    const boundingBox = img.getBoundingClientRect();
    const x = (e.clientX - boundingBox.left) - (boundingBox.width / 2);
    const y = (e.clientY - boundingBox.top) - (boundingBox.height / 2);
    
    setHoverPosition({ x, y });
  };
  const windowWidth = window.innerWidth;

  return (

    // 
    <motion.div
      exit={"exit"}
      variants={fastPageAnimation}
      initial={`hidden`}
      animate={`show`}
      className="flex flex-wrap justify-around items-center
      screen bg-blue overflow-hidden
      "
    >
      <Wave />

      <motion.div
      className="flex flex-col text-lg md:text-2xl lg:text-4xl mb-10 w-max lg:z-20">
        <div
          className="overflow-hidden lg:w-fit lg:h-fit p-1 m-2 "
        >
          <motion.h1 variants={titleAnim} 
          className=" max-lg:text-center text-white">
            Pioneers of tomorrow:  
          </motion.h1>
        </div>
        <div className="overflow-hidden  lg:w-fit lg:h-fit p-1 m-2">
          <motion.h1 className="max-lg:text-center text-white" variants={titleAnim}>
          Microsoft Learn Student Ambassadors{" "}
          </motion.h1>
        </div>
        <div className="overflow-hidden  lg:w-fit lg:h-fit p-1 m-2">
          <motion.h1 className="max-lg:text-center text-white" variants={titleAnim}>
          Air University{" "}
          </motion.h1>
        </div>
      </motion.div>

      <div className="z-10 " onMouseMove={handleMouseMove}>
        <motion.img
          variants={scrollReveal}
          whileHover={windowWidth >1060?{ x: hoverPosition.x, y: hoverPosition.y}:{}}
          // transition={{ duration: 0.3, ease: "easeOut" }} // Smooth transition settings
          className="w-96 object-contain"
          src="https://iili.io/Ja7wXvj.png"
          alt=""
        />
      </div>
    </motion.div>
  )
}

export default Hero
