import { fade, scrollReveal } from "../../../animation"
import { motion, useInView } from "framer-motion"
import { useRef, useState } from "react"

import { Link } from "react-router-dom"

export default function AboutText() {
  const valuesContainer = useRef(null)
  const isInView = useInView(valuesContainer, { amount: 0.25 })
  return (
    <div className="bg-white max-lg:-translate-y-[20%]">
      {/*  md:mt-24 lg:mt-20 */}
      <div className="mx-auto lg:mt-20 lg:py-5 lg:px-8">
        <div
          ref={valuesContainer}
          className="relative isolate overflow-hidden bg-blue-600 px-6 py-16 shadow-2xl sm:rounded-3xl  lg:flex  "
        >
          <motion.div
            variants={scrollReveal}
            animate={isInView ? "show" : "hidden"}
            initial="hidden"
            className="mx-auto  text-center lg:mx-0 lg:flex-auto lg:py-8 lg:text-center"
          >
            <h2 className="text-lg md:text-2xl lg:text-4xl font-bold tracking-tight text-white ">
              Our Values
            </h2>
            <div className="flex justify-center">
              <p className="w-[20rem] sm:w-[30rem] md:w-[40rem] lg:w-[50rem] 
              mt-6  leading-8 text-gray-300
              text-sm md:text-md lg:text-lg
              ">
                At Microsoft Learn Student Ambassadors (MLSA), our actions and
                decisions are guided by a set of core values that define who we
                are and what we stand for. These values are the foundation of
                our community and the driving force behind everything we do.
              </p>
            </div>
            <h2 className="text-lg md:text-2xl lg:text-4xl mt-4 font-bold tracking-tight text-white">
              Our Mission
            </h2>
            <div className="flex justify-center">
              <p className="w-[20rem] sm:w-[30rem] max-sm:mx-4 md:w-[40rem] lg:w-[50rem]
              text-sm md:text-md lg:text-lg
              mt-6  leading-8 text-gray-300">
                Our goal at Microsoft Learn Student Ambassadors (MLSA) is to be
                a catalyst for improvement in the technological landscape. Our
                goal is to motivate, educate, and inspire students to embrace
                innovation, seize chances, and have a significant effect on the
                Technology sector.
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  )
}
