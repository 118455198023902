import React from "react";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import { scrollRevealUp } from "../../../animation";

const AboutUs = () => {
  const aboutUs = useRef(null);
  const isInView = useInView(aboutUs, { amount: 0.3 });
  const isMobile = window.innerWidth < 500; //Add the width you want to check for here (now 768px)

  return (
    <motion.div ref={aboutUs} className="overflow-hidden">
      <motion.section
        variants={!isMobile ? scrollRevealUp : {}}
        animate={isInView ? "show" : "hidden"}
        initial="hidden"
        className="m-4 pt-8 gap-3 flex flex-col lg:flex-row items-start justify-between px-4"
      >
        <div className="lg:w-1/2 bg-blue-500 py-10 order-2 lg:order-1 flex justify-center rounded-md mr-3 ">
          <img
            src="https://iili.io/Ja7wXvj.png"
            alt="aboutus"
            className="w-2/3 rounded-md"
          />
        </div>

        <div className=" lg:w-1/2 order-1 lg:order-2 text-center lg:text-left">
          <h2 className="text-lg md:text-2xl lg:text-4xl text-gray-800 font-bold mb-4">
            About Us
          </h2>
          <p className="text-sm md:text-md lg:text-lg text-gray-600 mb-8">
            We at MLSA Air University, are a diverse group of enthusiastic
            university students. We share a common interest in technology and a
            profound commitment to making a difference. Our activities include
            organizing workshops, hosting hackathons, delivering seminars, and
            spreading the word about Microsoft's products and services. Our goal
            is to encourage a culture of invention, cooperation, and learning
            among the students. By offering tools, assistance, and mentorship,
            we want to inspire and educate the upcoming generation of IT
            leaders.{" "}
          </p>
        </div>
      </motion.section>
    </motion.div>
  );
};

export default AboutUs;
