// this is like a popup, so we gotta figure that out first
function TermsAndConditions() {
  return (
    // this will have to be centered within the main page (act as popup)
    <div className="bg-blue rounded-lg w-[75%] md:w-[60%] shadow-lg shadow-gray-500 flex flex-col pt-5 pb-5 pl-8 pr-8 items-center justify-items-center text-white ">
      <h1 className="text-2xl mb-2 font-bold">Terms and Conditions</h1>
      <div className="font-sans ">
        Varius. Dolor lectus senectus malesuada blandit habitant dictumst auctor
        taciti urna. Hac natoque augue lacinia sem primis mus ridiculus volutpat
        quam velit sociis nec. Feugiat nam eu facilisi platea imperdiet rhoncus
        nulla dignissim, lectus eu, inceptos massa ullamcorper. Ut conubia
        tortor. Vivamus elit nonummy ridiculus id magnis orci porttitor. Hac
        dictumst magnis mauris. At, etiam cubilia tincidunt praesent montes
        placerat montes morbi. Vulputate tellus. Montes Arcu id sagittis
        quisque. Laoreet aliquam, dui bibendum, consequat lobortis adipiscing
        cras facilisi pharetra curae;. Gravida phasellus condimentum ultricies
        nisl interdum In vehicula litora torquent non sem adipiscing in. Vitae
        urna praesent sapien pellentesque aliquam.
      </div>
      <div className="flex flex-row gap-5 items-center m-5 mr-7 ">
        <div id="terms-checkbox ">
          <input
            className="h-6 w-6"
            type="checkbox"
            id="terms-checkbox"
            name="terms-checkbox"
            value="terms-checkbox"
          />
        </div>
        <div id="terms-text" className="">
          I agree to the terms and conditions being stated by the MLSA Air
          University and would abide by them at all times. I understand that
          failure to comply with them will lead to disqualification.
        </div>
      </div>
      <div>
        <button className="bg-dark-blue shadow-md shadow-gray-600 text-white rounded-lg pt-2 pb-2 pl-10 pr-10">
          Submit
        </button>
      </div>
    </div>
  );
}

export default TermsAndConditions;
