import { CgFacebook } from "react-icons/cg"
import { ImInstagram } from "react-icons/im"
import { GrLinkedinOption } from "react-icons/gr"
import { Link } from "react-router-dom"
function Footer() {
  return (
    <div className="h-68 bg-dark-blue flex flex-col gap-5 sm:grid  sm:grid-rows-1 sm:grid-cols-3 max-md:col-span-12 sm:gap-0 sm:items-start text-white pt-0 sm:pt-10 pb-5 pl-5 pr-5 sm:pl-10 sm:pr-10">
      <div className="flex flex-col gap-5 sm:justify-between  sm:items-start items-center sm:h-full">
        <div id="logo-div" className="flex flex-row items-center pt-5 sm:pt-0 ">
          <img
            src="https://iili.io/Ja7wXvj.png"
            alt=""
            className="w-24 h-24 object-contain"
          />
          <div className="flex flex-col text-md font-extralight">
            <h1>Microsoft Learn</h1>
            <h1>Student Ambassadors</h1>
            <h1>Air University</h1>
          </div>
        </div>

        <div className="sm:ml-8 flex flex-row gap-3 justify-evenly items-center w-24">
          <a
            href="https://www.facebook.com/AUMLSA/"
            rel="noreferrer"
            target="_blank"
          >
            <div className="h-10 w-10 flex justify-center items-center bg-white hover:bg-gray-200 p-1 rounded-lg">
              <CgFacebook className="h-8 w-8 text-[#1d3558]" />
            </div>
          </a>
          <a
            href="https://www.instagram.com/aumlsa/"
            rel="noreferrer"
            target="_blank"
          >
            <div className="h-10 w-10 flex justify-center items-center bg-white hover:bg-gray-200 p-1 rounded-lg">
              <ImInstagram className="h-7 w-7 text-[#1d3558]" />
            </div>
          </a>
          <a
            href="https://www.linkedin.com/company/aumlsa/"
            rel="noreferrer"
            target="_blank"
          >
            <div className="h-10 w-10 flex justify-center items-center bg-white hover:bg-gray-200 p-1 rounded-lg">
              <GrLinkedinOption className="h-7 w-7 text-[#1d3558]" />
            </div>
          </a>
        </div>
      </div>

      <div
        id="quick-links"
        className="sm:ml-10 flex flex-col items-center sm:items-start "
      >
        <h1 className="text-2xl mb-2 ">Quick Links</h1>
        <div className="flex flex-col gap-1 w-40 items-center sm:items-start">
          <Link
            onClick={() => window.scrollTo(0, 0)}
            to="/"
            className="text-md hover:scale-105 transform transition-all duration-200 ease-in "
          >
            Home
          </Link>

          <Link
            onClick={() => window.scrollTo(0, 0)}
            to="/events"
            className="text-md hover:scale-105 transform transition-all duration-200 ease-in "
          >
            Events
          </Link>
          <Link
            onClick={() => window.scrollTo(0, 0)}
            to="/teams"
            className="text-md hover:scale-105 transform transition-all duration-200 ease-in "
          >
            Our Teams
          </Link>
          <Link
            onClick={() => window.scrollTo(0, 0)}
            to="/code-air"
            className="text-md hover:scale-105 transform transition-all duration-200 ease-in "
          >
            Code Air
          </Link>
          <Link
            onClick={() => window.scrollTo(0, 0)}
            to="/contact-us"
            className="text-md hover:scale-105 transform transition-all duration-200 ease-in "
          >
            Contact Us
          </Link>
          <Link
            onClick={() => window.scrollTo(0, 0)}
            to="/mlsa-and-datacamp"
            className="text-md hover:scale-105 transform transition-all duration-200 ease-in "
          >
            MLSA & Datacamp
          </Link>
        </div>
      </div>
      <div
        id="follow-us"
        className="sm:ml-10 flex flex-col items-center sm:items-start"
      >
        <h1 className="text-2xl mb-2">Follow Us</h1>
        <div className="text-center sm:text-start">
          Stay connected with us and join our vibrant tech community by
          following us on our social media channels. Follow our journey, stay
          updated on our latest events and projects, and be part of the
          conversation about all things tech. Connect with us on Twitter,
          Facebook, LinkedIn, and more to be a part of the MLSA experience.
        </div>
      </div>
    </div>
  )
}

export default Footer
