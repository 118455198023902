import { motion, AnimatePresence } from "framer-motion"
import { useState } from "react"
import "./teams.css"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader

function Events() {
  const slides = [
    {
      image: "https://iili.io/Ja7NZDQ.png",
      alt: "Operations Team",
      heading: "Operations Team",
      description:
        "At present, our Operations team is spearheaded by two prominent individuals, Hashaam and Faizan. This dynamic duo leads a dedicated team that plays an integral role in orchestrating flawless events at MLSA Air University. Their responsibilities encompass a wide range of tasks, from reserving the auditorium and ensuring security to warmly welcoming guests and coordinating all the essential technical equipment for each event. The Operations team's unwavering commitment and tireless efforts are nothing short of crucial in ensuring the triumph of every event. Their expertise and dedication are the driving force behind our successful event management, and their invaluable contributions are a cornerstone of our organization's mission.",
    },
    {
      image: "https://iili.io/Ja7e249.jpg",
      alt: "Social Media Management Team",
      heading: "Social Media Management Team",
      description:
        "The Social Media Management Team at Microsoft Learn Student Ambassadors (MLSA) consists of a dynamic group of creative and tech-savvy individuals who are passionate about harnessing the full potential of social media to connect, engage, and inspire our vibrant community. Under the capable leadership of Kashaf Fatima, this team orchestrates all aspects of our social media presence. They curate and publish posts related to MLSA AU events, talks, and all pertinent updates, ensuring that our online content reflects our mission and values. Our social media handles, which span LinkedIn, Instagram, and Facebook, are thoughtfully managed by this dedicated team, providing a consistent and engaging online experience for our audience.",
    },
    {
      image: "https://iili.io/Ja7eF2e.jpg",
      alt: "Documentation & Proposal Team",
      heading: "Documentation & Proposal Team",
      description:
        "Our Documentation and Proposals team, under the capable leadership of Qasim Haider, is instrumental in our organization. This dedicated team is responsible for two vital functions: meticulously documenting event reports and crafting persuasive emails for sponsorship proposals directed towards various entities. Accurate documentation is a cornerstone for the successful operation of any society or entity, and we are fortunate to have an exceptional team to handle this pivotal role. Qasim Haider, along with his skilled team, ensures that every detail is recorded and that our sponsorship proposals are not just professional but also compelling. Their commitment to precision and excellence contributes significantly to our overall success, and we greatly value their contributions.",
    },
    {
      image: "https://iili.io/Ja7eIEP.jpg",
      alt: "Think Tank Team",
      heading: "Think Tank Team",
      description:
        "Yahya Bin Naveed and Areesh Ali Abdullah are at the helm of our Think Tank team at MLSA AU. This exceptional team is entrusted with the task of delving into in-depth research and investigation related to MLSA AU events. Their brainstorming sessions yield innovative ideas aimed at enhancing events and making them more appealing to both attendees and guests. The Think Tank team serves as a bustling hub of intellectual activity within MLSA AU, constantly working to propose solutions, generate creative ideas, and engage with the community on pivotal topics. At Microsoft Learn Student Ambassadors, Air University, we encourage anyone with a fervor for brainstorming and proposing inventive solutions to join this team and contribute to our collective mission of innovation and excellence.",
    },
    {
      image: "https://iili.io/Ja7e7yJ.jpg",
      alt: "Developers Team",
      heading: "Developers Team",
      description:
        "At MLSA AU, the Development team, led by Farhat Abbas and Zuhair Saleem, holds the reins in crafting applications tailored to the society's requirements. Farhat Abbas and Zuhair Saleem head this crucial team, taking charge of all development-related aspects within the society. Their scope encompasses a wide array of applications, from native web and Android apps to cross-platform solutions. The team is at the forefront of innovation, ensuring that MLSA AU stays technologically relevant. We invite anyone with a passion for programming and development to become a part of this dynamic team at Microsoft Learn Student Ambassadors, Air University. Your skills and enthusiasm can contribute to our mission of creating cutting-edge applications that benefit our society and beyond.",
    },
    {
      image: "https://iili.io/Ja7e1CN.jpg",
      alt: "Visual Enhancement Crew",
      heading: "Visual Enhancement Crew",
      description:
        "Led by the creative genius of Fatima Shams and Saad Ali Sherazi, the Visual Enhancement Crew at MLSA AU is where the realms of artistry and innovation converge. This dynamic duo is the driving force behind elevating our visual content, infusing each project with aesthetic brilliance that not only captivates but also communicates effectively. Their transformative touch works magic in enhancing the visual appeal of our initiatives, setting us apart in the ever-evolving digital landscape. Their artistic prowess and innovative approach bring a unique dimension to our work, enriching the visual experience for our audience and stakeholders. Together, they redefine and elevate the visual narrative of our mission and aspirations, leaving an indelible impression.",
    },
    {
      image: "https://iili.io/Ja7eEGI.jpg",
      alt: "Content Writing Team",
      heading: "Content Writing Team",
      description:
        "Under the guidance of Hassaan Ul Haq, the Content Writing team at MLSA AU takes the lead in crafting compelling content tailored for an audience deeply passionate about technology. This team's work involves generating content that finds its way onto diverse social media platforms like LinkedIn, Instagram, and Facebook, where it serves to generate excitement and anticipation for upcoming events. The impact of their well-crafted words resonates with our community, igniting interest and engagement. At Microsoft Learn Student Ambassadors, Air University, we extend a warm invitation to individuals who possess the power of words and a desire to make a meaningful impact on society. Join our Content Writing team, and contribute your skills and passion to our mission of promoting technological innovation.",
    },
    {
      image: "https://iili.io/Ja7eG4t.jpg",
      alt: "Graphics Designing Team",
      heading: "Graphics Designing Team",
      description:
        "Javeria Rehman spearheads the Graphics Design team at MLSA AU, a group laser-focused on crafting visually captivating content. This dynamic team holds the pivotal responsibility of designing eye-catching social media content, including banners, posters, and posts, strategically aimed at capturing the attention of tech enthusiasts for our events. Comprising highly passionate and creative individuals, the team thrives on leveraging cutting-edge tools and technologies to produce visually engaging content that leaves a lasting impression. We extend an open invitation to all those with a fervor for creating visually appealing content to join our ranks at Microsoft Learn Student Ambassadors, Air University. Your creative flair can contribute to our mission of enhancing our digital presence and attracting tech aficionados.",
    },
    {
      image: "https://iili.io/Ja7eXvs.jpg",
      alt: "Marketing and Community Outreach Team",
      heading: "Marketing and Community Outreach Team",
      description:
        "The Marketing and Community Outreach team at MLSA Air University is a vital, dynamic unit dedicated to disseminating critical information, events, and updates related to the institution, extending their reach within and beyond the university. Led by accomplished individuals, Abdul Haseeb and Mahrukh Yaseen, this team plays a central and pivotal role in fostering engagement with external organizations. Their functions span a broad spectrum of responsibilities, from securing sponsors to identifying effective communication channels. The Marketing and Community Outreach team takes a proactive and forward-thinking approach, consistently ensuring that the university's message reaches the right audience, thus leaving a lasting impact on the community.",
    },

    // Add more slide data as needed
  ]

  const [currentIndex, setCurrentIndex] = useState(0)

  const handleNext = () => {
    setTimeout(
      () =>
        setCurrentIndex((prevIndex) =>
          prevIndex + 1 === slides.length ? 0 : prevIndex + 1
        ),
      250
    )

    console.log(currentIndex)
  }
  const handlePrevious = () => {
    setTimeout(
      () =>
        setCurrentIndex((prevIndex) =>
          prevIndex - 1 < 0 ? slides.length - 1 : prevIndex - 1
        ),
      250
    )

    console.log(currentIndex)
  }

  const slidersVariants = {
    hover: {
      scale: 1.2,
      backgroundColor: "#1d3558",
      color: "white",
    },
  }

  const [isLeftHover, setIsLeftHover] = useState(false)
  const [isRightHover, setIsRightHover] = useState(false)

  return (
    <>
      <div
        className="pt-16 bg-gray-100 overflow-hidden
      min-h-screen 
      "
      >
        <section className="  flex flex-col lg:flex-col items-center justify-between px-4">
          <div
            className={`
             flex lg:w-3/4 lg:h-[80vh]
           justify-center loadable bg-slate-300 `}
          >
            <Carousel
              selectedItem={currentIndex}
              showThumbs={false}
              showStatus={false}
              showIndicators={false}
              showArrows={false}
              swipeable={false}
            >
              {slides.map((slide, index) => (
                <div key={index} className=" relative h-full">
                  <img
                    src={slide.image}
                    className="object-cover w-full h-full"
                    // width="100%"
                    alt=""
                  />
                </div>
              ))}
            </Carousel>
          </div>

          <div
            className="m-4 lg:w-3/4 order-1 lg:order-2 text-center 
          lg:text-left flex flex-row items-center align-top"
          >
            <div className="m-2 ">
              <div className="flex justify-between items-start">
                <h2 className="text-lg md:text-2xl lg:text-4xl text-gray-800 font-bold mb-4 ">
                  {slides[currentIndex].heading}
                </h2>
                <div
                  className="
              max-lg:items-center flex gap-2"
                >
                  <motion.button
                    variants={slidersVariants}
                    whileHover="hover"
                    onClick={() => handlePrevious()}
                    onHoverStart={() => {
                      setIsLeftHover(true)
                    }}
                    onHoverEnd={() => {
                      setIsLeftHover(false)
                    }}
                    className="bg-gray-300 text-dark-blue font-extrabold 
                    text-lg w-10 h-10 rounded-full flex justify-center items-center"
                  >
                    {!isLeftHover ? (
                      <img
                        src="/assets/ios-back.svg"
                        className="w-6 h-6"
                        alt=""
                      />
                    ) : (
                      <img
                        src="/assets/ios-back-white.svg"
                        className="w-6 h-6"
                        alt=""
                      />
                    )}
                  </motion.button>
                  <motion.button
                    variants={slidersVariants}
                    whileHover="hover"
                    onHoverStart={() => {
                      setIsRightHover(true)
                    }}
                    onHoverEnd={() => {
                      setIsRightHover(false)
                    }}
                    onClick={() => handleNext()}
                    className="bg-blue  text-white font-extrabold text-xl 
                    w-10 h-10 rounded-full flex justify-center items-center"
                  >
                    <img
                        src="/assets/ios-back-white.svg"
                        className="w-6 h-6 transform rotate-180"
                        alt=""
                      />
                  </motion.button>
                </div>
              </div>
              <p className="text-sm md:text-md lg:text-lg text-gray-600 mb-8">
                {slides[currentIndex].description}
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Events
