import { React, useState } from "react"
import { AnimatePresence, motion } from "framer-motion"

import { Link } from "react-router-dom"
import { containerVars, menuVars, mobileLinkVars, lineAnim } from "../animation"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"

const Navbar = () => {
  const [active, setActive] = useState(0)
  const [open, setOpen] = useState(false)
  const location = useLocation()

  useEffect(() => {
    // Find the index of the current route in the navItems array
    const currentRoute = location.pathname;
    const currentIndex = navItems.findIndex((item) => item.to === currentRoute);
  
    // Set the active index if found, otherwise, set it to -1
    setActive(currentIndex !== -1 ? currentIndex : -1);
  }, [location]);

  const navItems = [
    { text: "Home", to: "/" },
    { text: "Code Air", to: "/code-air" },
    { text: "Events", to: "/events" },
    { text: "Teams", to: "/teams" },
    { text: "Contact Us", to: "/contact-us" },
  ]

  const toggleMenu = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  return (
    <header>
      <motion.nav
        variants={containerVars}
        initial="hidden"
        animate="show"
        className={`${
          active === 2 || active === 3 ? "bg-white" : "bg-blue"
        } nav 
        py-4 flex justify-around items-center text-white
         transition-all duration-500`}
      >
        <Link onClick={() => setActive(0)} to="/" className="">
          <div className="flex gap-2 items-center">
            <img
              src="https://iili.io/Ja7wXvj.png"
              alt=""
              className="w-12 h-12 object-contain"
            />
            <div
              className={`${
                active === 2 || active === 3 ? "text-blue-600" : "text-white"
              } flex flex-col text-xs`}
            >
              <h1>Microsoft Learn</h1>
              <h1>Student Ambassadors</h1>
              <h1>Air University</h1>
            </div>
          </div>
        </Link>

        <div className="flex gap-5 text-lg max-lg:hidden">
          {navItems.map((item, index) => (
            <Link
            key={index}
            className={`${
              active === 2 || active === 3 ? "text-blue-600" : "text-white"
            } relative w-max`}
            variants={mobileLinkVars}
            to={item.to}
            onClick={() => setActive(index)}
          >
            {item.text}
            {active !== -1 && (
              <motion.div
                variants={lineAnim}
                animate={active === index ? "show" : "hidden"}
                className={`${
                  active === 2 || active === 3 ? "bg-blue" : "bg-white"
                } absolute  h-1`}
              />
            )}
          </Link>
          ))}
        </div>

        <div className="max-lg:hidden">
          <a
            href="http://bit.ly/mlsaaircommunity"
            rel="noreferrer"
            target="_blank"
          >
            <button className="bg-dark-blue rounded-md px-12  py-1.5">
              Join Us
            </button>
          </a>
        </div>
        <div
          className="cursor-pointer lg:hidden text-md text-white"
          onClick={toggleMenu}
        >
          <img
            src={`/assets/hamburger${
              active === 2 || active === 3 ? "-black" : ""
            }.svg`}
            className="w-10 h-10 object-contain"
            alt=""
          />
        </div>
      </motion.nav>
      <AnimatePresence>
        {open && (
          <motion.div
            variants={menuVars}
            initial="initial"
            animate="animate"
            exit="exit"
            className="z-20 fixed left-0 top-0 w-full h-screen origin-top bg-dark-blue text-white p-10"
          >
            <div className="flex h-full flex-col">
              <div className="flex justify-between">
                <h1 className="text-lg text-white">MLSA-AIR</h1>
                <p
                  className="cursor-pointer text-md text-white"
                  onClick={toggleMenu}
                >
                  Close
                </p>
              </div>
              <motion.div
                variants={containerVars}
                initial="initial"
                animate="open"
                exit="initial"
                className="flex flex-col h-full justify-center font-lora items-center gap-4 "
              >
                {navItems.map((link, index) => {
                  return (
                    <div className="overflow-hidden">
                      <MobileNavLink
                        key={index}
                        title={link.text}
                        href={link.to}
                        toggleMenu={toggleMenu}
                      />
                    </div>
                  )
                })}
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  )
}

export default Navbar

const MobileNavLink = ({ title, href, toggleMenu }) => {
  return (
    <motion.div
      variants={mobileLinkVars}
      className="max-lg:text-3xl max-md:text-2xl max-sm:text-xl uppercase text-white"
    >
      <Link onClick={() => toggleMenu()} to={href}>
        {title}
      </Link>
    </motion.div>
  )
}
