import { motion, useInView } from "framer-motion"
import { useRef, useState } from "react"
import {
  fastPageAnimation,
  scrollReveal,
  photoAnimDelay,
} from "../../../animation"
import { Link } from "react-router-dom"

const HomePageEvents = () => {
  const carousel = useRef(null)
  const isInView = useInView(carousel, { amount: 0.25 })

  const items = [
    { src: "https://iili.io/Ja7wvG1.jpg", alt: "Tensorflow with Rumi" },
    { src: "https://iili.io/Ja7lFGR.jpg", alt: "What's Teching" },
    { src: "https://iili.io/Ja7h6la.jpg", alt: "Code Air" },
  ]
  const isMobile = window.innerWidth < 500 //Add the width you want to check for here (now 768px)

  return (
    <motion.div
      className="flex justify-center items-center flex-col min-h-screen overflow-hidden"
      ref={carousel}
      variants={!isMobile ? fastPageAnimation : {}}
      animate={isInView ? "show" : "hidden"}
      initial="hidden"
    >
      <div>
        <h1 className="text-lg md:text-2xl lg:text-4xl text-gray-800 font-bold mb-4">Events</h1>
      </div>
      <motion.div className="grid grid-cols-12 max-lg:gap-2">
        {items.map((item, index) => (
          <Item
            key={index}
            idx={index}
            src={item.src}
            alt={item.alt}
            isInView={isInView}
          />
        ))}
      </motion.div>
      <motion.div
        className="flex justify-center my-4"
        variants={!isMobile ? scrollReveal : {}}
        animate={isInView ? "show" : "hidden"}
      >
        <Link
          onClick={() => window.scrollTo(0, 0)}
          to={"/events"}
          className="bg-dark-blue  py-2 px-6 my-7 flex items-center text-white"
        >
          See our Events
        </Link>
      </motion.div>
    </motion.div>
  )
}
// item

const Item = ({ src, alt, isInView, idx }) => {
  const isMobile = window.innerWidth < 500 //Add the width you want to check for here (now 768px)

  return (
    <motion.div
      className={`col-span-4 max-md:col-span-12  max-lg:col-span-6
            cursor-pointer hover:z-20 
            max-md:mx-5 max-md:my-3
            md:hover:scale-110 transition duration-300 ease-out 
            ${
              idx === 1 ? "z-10 max-lg:hidden lg:shadow-2xl max-md:block" : ""
            }`}
    >
      <motion.img
        variants={!isMobile ? photoAnimDelay(idx) : {}}
        animate={isInView ? "show" : "hidden"}
        src={src}
        className="w-96 h-96 
        max-sm:w-72 max-sm:h-72  rounded-xl object-cover "
        alt={alt}
      />
    </motion.div>
  )
}

export default HomePageEvents
