import { motion } from "framer-motion"
import { useState } from "react"
import { fade, pageAnimation, photoAnim } from "../../animation"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import emailjs from "emailjs-com"
import { Helmet } from "react-helmet"

export default function ContactUsScreen() {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [message, setMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const baseURL = ""

  function resetForm() {
    setEmail("")
    setName("")
    setMessage("")
  }

  function successToast() {
    toast.success("We have received your Message!", {
      icon: "👏",
      style: {
        borderRadius: "10px",
        background: "#218ada",
        color: "#fff",
      },
    })
  }
  function failureToast() {
    toast.error("Something went wrong! Try Later.", {
      icon: "😢",
      style: {
        borderRadius: "10px",
        background: "#f12d2d",
        color: "#fff",
      },
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = {
      email,
      name,
      message,
    }
    setIsLoading(true)
    emailjs
      .send(
        "service_duuuvms",
        "template_dlbar67",
        formData,
        "M_AMcYTMwq6p4G8yx"
      )
      .then(
        (response) => {
          console.log("Email sent successfully:", response)
          successToast()
          resetForm()
          setIsLoading(false)
        },
        (error) => {
          console.error("Failed to send email:", error)
          failureToast()
          // resetForm()
          setIsLoading(false)
        }
      )
  }

  return (
    <>
      <Helmet>
        <title>Contact Us | MLSA AU</title>
        <meta
          name="description"
          content="Have questions or ideas? Contact the Microsoft Learn Student Ambassadors at Air University. We welcome students, tech enthusiasts, and potential partners to get in touch."
        />
        <meta
          name="keywords"
          content="Contact MLSA, Microsoft Learn Student Ambassadors, Air University, Student Queries, Tech Collaboration, Connect with Us"
        />

        <meta
          property="og:description"
          content="Looking to connect with MLSA at Air University? Reach out to us with your questions, comments, or collaboration ideas. We're here to assist!"
        />
        <meta
          property="og:image"
          content="URL to a relevant image for the contact page"
        />
        <meta property="og:url" content="URL of the contact page" />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Get in Touch with MLSA AU - Contact Us"
        />
        <meta
          name="twitter:description"
          content="Connect with the Microsoft Learn Student Ambassadors at Air University. We're eager to hear from students, tech enthusiasts, and potential partners."
        />
        <meta
          name="twitter:image"
          content="URL to a relevant image for the contact page"
        />
      </Helmet>

      <motion.div className="grid grid-cols-12 screen ">
        <div className="col-span-5 max-md:col-span-12 bg-blue ">
          <div className="flex justify-center items-center h-full max-md:h-56">
            <motion.img
              variants={photoAnim}
              initial="hidden"
              animate="show"
              src="https://iili.io/Ja7wXvj.png"
              className="w-4/5 max-md:w-52"
              alt=""
            />
          </div>
        </div>
        <motion.div
          variants={pageAnimation}
          initial="hidden"
          animate="show"
          exit="exit"
          className="col-span-7 md:mt-10 md:mx-10 max-md:mx-3 max-md:my-2 max-md:col-span-12"
        >
          <div>
            <h1 className="text-black text-2xl font-black">Contact Us</h1>
            <motion.p variants={fade} className="text-stone-400 ">
              Our team at Microsoft Learn Student Ambassadors (MLSA) values your
              opinions, comments, and suggestions. We're happy to assist,
              whether you're a fellow student looking to connect, a tech
              enthusiast with a query, or a possible partner eager to
              collaborate. Please get in touch with us.
            </motion.p>
          </div>

          <div className="mt-10 text-black">
            <form onSubmit={handleSubmit} className="flex flex-col gap-2">
              <motion.div variants={fade} className="flex flex-col">
                <label className="text-black">Name</label>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="text-black h-10 lg:w-4/5 border-2 drop-shadow-xl p-2 rounded-md"
                  type="text"
                  name="name"
                  required
                />
              </motion.div>
              <motion.div variants={fade} className="flex flex-col">
                <label className="text-black">Email</label>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="text-black h-10 lg:w-4/5 border-2 drop-shadow-xl p-2 rounded-md"
                  type="email"
                  name="email"
                  required
                />
              </motion.div>

              <motion.div variants={fade} className="flex flex-col">
                <label className="text-black">Message</label>
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="text-black h-40 lg:w-4/5 border-2 drop-shadow-xl p-2 rounded-md"
                  type="textarea"
                  name="message"
                  rows="10"
                  minLength="50"
                  required
                />
              </motion.div>

              <br />
              {!isLoading && (
                <input
                  className="mx-auto w-max text-lg px-8 py-2 m-2 
                  bg-dark-blue text-white 
                  cursor-pointer
                  hover:scale-105 transform transition-all duration-200 ease-out
                md:m-0"
                  type="submit"
                  value="Submit"
                />
              )}
              {isLoading && <CircularIndicator />}

              <br />
            </form>
          </div>
        </motion.div>
      </motion.div>
    </>
  )
}

function CircularIndicator() {
  return (
    <div role="status" className="relative mx-auto w-max m-2 md:m-0">
      <svg
        aria-hidden="true"
        className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentColor"
        />
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  )
}
