import React from "react"

const Logo = () => {
  return (
    <div className="bg-blue h-36 sm:h-48 md:h-64 lg:h-80 flex items-center 
    justify-center mb-5 w-full">
      <img
        src="https://iili.io/Ja7haSf.png"
        alt=""
        className="h-32 max-md:h-32 max-sm:h-20 object-cover "
      />
    </div>
  )
}

export default Logo
