import React from "react"
import { motion, useInView } from "framer-motion"
import { useRef, useState } from "react"
import { scrollReveal, scrollRevealUp } from "../../../animation"
import { Link } from "react-router-dom"

const RecentEvent = () => {
  const aboutUs = useRef(null)
  const isInView = useInView(aboutUs, { amount: 0.1 })

  return (
    <motion.div ref={aboutUs} className="overflow-hidden py-8">
      <h1 className="text-lg md:text-2xl lg:text-4xl text-gray-800   font-normal text-center ">Recent Event</h1>
      <motion.section
        variants={scrollReveal}
        animate={isInView ? "show" : "hidden"}
        initial="hidden"
        className="m-4 mb-0 lg:mx-24 flex flex-col lg:flex-row items-center lg:items-start justify-between "
      >
        <div className="lg:w-1/2 h-full py-5 order-2 lg:order-1 flex justify-center ">
          <img
            src="https://iili.io/J5DIq3Q.jpg"
            alt="WebAir - front-end web dev competition"
            className="w-full h-full object-cover"
          />
        </div>

        <div className="m-4 lg:w-1/2 order-1 lg:order-2 text-center lg:text-left">
          <h2 className="text-lg md:text-2xl lg:text-4xl text-gray-800 font-bold mb-4">
            WebAir - front-end web dev competition
          </h2>
          <p className="text-sm md:text-md lg:text-lg text-gray-600 mb-8">
            MLSA Air University, in collaboration with Air University Computing and Innovation Society (AUCIS) and Air University Women in Computing (AUWiC) conducted the first front-end web development competition at Air University Islamabad which focused on the use of web technologies essentials i.e., HTML5 and CSS3 to design the webpage on the basis of provided constraints and UIs. The participants were made familiar with the power of GitHub and enabled them to deploy the website on GitHub Pages which was graded from there. The competition was a success with the winning team provided with the cash-price of PKR 5000 and LinkedIn Premium vouchers.
          </p>
        </div>
      </motion.section>
    </motion.div>
  )
}

export default RecentEvent
