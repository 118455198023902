// IMPORTS -
import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
import { scrollRevealUp } from "../../../animation";

const AirTech = () => {
  const AirTech = useRef(null);
  const isInView = useInView(AirTech, { amount: 0.3 });
  const isMobile = window.innerWidth < 500;
  return (
    <>
      <motion.div ref={AirTech} className="overflow-hidden">
        <motion.section
          variants={!isMobile ? scrollRevealUp : {}}
          animate={isInView ? "show" : "hidden"}
          initial="hidden"
          className="m-4 pt-8 gap-3 flex flex-col lg:flex-row items-start justify-between px-4"
        >
          <div className="min-w-full overflow-hidden flex justify-center">
            <div className="overflow-hidden lg:w-[80vw] md:w-[80vw]">
              <iframe
                src="https://www.youtube.com/embed/72xTIYjKpb0?rel=0&amp;controls=0&amp;showinfo=0&amp;autoplay=1&amp;cc_load_policy=1&amp;mute=1"
                title="MLSA"
                style={{ width: "100%" }}
                className="lg:w-full lg:h-[100vh] md:h-[50vh]"
                frameborder="0"
              />
            </div>
          </div>
        </motion.section>
      </motion.div>
    </>
  );
};

export default AirTech;
