import { Link } from "react-router-dom"
import { AiOutlineArrowRight } from "react-icons/ai"
import { motion, useInView } from "framer-motion"
import { useRef, useState } from "react"
import { scrollReveal, scrollRevealUp } from "../../../animation"

function Teams() {
  const teams = useRef(null)
  const isInView = useInView(teams, { amount: 0.3 })
  const isMobile = window.innerWidth < 500; //Add the width you want to check for here (now 768px)

  return (
    <div ref={teams} className="bg-blue py-6 overflow-hidden">
      <motion.div
      variants={!isMobile? scrollRevealUp: {}}
      animate={isInView ? "show" : "hidden"}
        initial="hidden"
        className="flex flex-col items-center"
      >
        <h1 className="font-bold text-lg md:text-2xl lg:text-4xl py-2 text-white">Our Teams</h1>
        <p className="pb-4 text-sm md:text-md lg:text-lg font-light spac text-white text-center">
          We have dynamic and competent teams for MLSA AU.
          <br />
          Because of the hardwork and passion of these teams, MLSA AU is one of
          the leading societies of Air University.
        </p>
        <div className="flex mx-20 flex-wrap lg:flex-row justify-evenly w-full">
          <img
            src="https://iili.io/Ja7wdEF.png"
            alt=""
            className="bg-slate-300
             w-[22rem] h-[20rem] 
             max-lg:w-[20rem] max-lg:h-[18rem] 
             max-md:w-[16rem] max-md:h-[14rem]
             object-cover

            my-3 rounded-md hover:scale-110 transition duration-300"
          />
          <img
            src="https://iili.io/Ja7wKYJ.png"
            alt=""
            className="bg-slate-300 w-[22rem] h-[20rem]
            max-lg:w-[20rem] max-lg:h-[18rem] 
            max-md:w-[16rem] max-md:h-[14rem]
            object-cover

            my-3 rounded-md hover:scale-110 transition duration-300"
          />
          <img
            src="https://iili.io/Ja7wT4n.png"
            alt=""
            className="bg-slate-300 w-[22rem] h-[20rem] my-3
            max-lg:w-[20rem] max-lg:h-[18rem] 
            max-md:w-[16rem] max-md:h-[14rem]
            object-cover
            rounded-md hover:scale-110 transition duration-300"
          />
        </div>
        <Link
          to={"/teams"}
          onClick={()=>window.scrollTo(0,0, )}
          className="bg-dark-blue  py-2 px-6 my-7 flex items-center text-white"
        >
          See Our Teams{" "}
          <span className="ml-2 text-lg">
            <AiOutlineArrowRight />
          </span>
        </Link>
      </motion.div>
    </div>
  )
}

export default Teams
