import React from "react";
import TermsAndConditions from "./terms-condition";
import TeamMemberInfo from "./team-member-info";
import PaymentInfo from "./payment-info";
function WebAirReg() {
  // TEST COMPONENT
  return (
    <div className="p-10">
      <h1>Welcome to Web Air Registration (test route)</h1>
      <TermsAndConditions />
      <PaymentInfo />
      {/* <TeamMemberInfo /> */}
    </div>
  );
}

export default WebAirReg;
