// IMPORTS -
import Hero from "./components/hero";
import AboutUs from "./components/about_us";
import Teams from "./components/teams";
import HomePageEvents from "./components/events";
import Codeair from "./components/code_air";
import AirTech from "./components/AirTech";
import { Helmet } from "react-helmet";

export default function HomeScreen() {
  return (
    <>
      <Helmet>
        <title>Home | MLSA AU</title>
        <meta
          name="description"
          content="Join the journey with Microsoft Learn Student Ambassadors at Air University. Discover our workshops, hackathons, and the flagship CodeAir competition. We're fostering a culture of innovation and learning in technology."
        />
        <meta
          name="keywords"
          content="MLSA, Microsoft Learn Student Ambassadors, Air University, Technology, Workshops, Hackathons, CodeAir, Tech Events, Student Leadership"
        />

        <meta
          property="og:description"
          content="Explore the vibrant world of MLSA at Air University. Engage in exciting tech events, workshops, and our premier CodeAir competition. We're building a community of future tech innovators."
        />
        <meta
          property="og:image"
          content="URL to a representative image for MLSA AU"
        />
        <meta property="og:url" content="URL of the homepage" />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="MLSA Air University - Empowering Future Tech Innovators"
        />
        <meta
          name="twitter:description"
          content="Step into the dynamic world of technology with MLSA at Air University. Participate in our enriching tech events and the celebrated CodeAir competition."
        />
        <meta
          name="twitter:image"
          content="URL to a representative image for MLSA AU"
        />
      </Helmet>
      <>
        {/* Hero component */}
        <Hero />
        {/* About Us component */}
        <AboutUs />

        {/* AirTech component */}
        <AirTech />

        {/* carousel component */}
        <HomePageEvents />
        {/* Teams component */}
        <Teams />
        {/* CodeAir */}
        <Codeair />
      </>
    </>
  );
}
