// IMPORTS -
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Notification = () => {
  const [isClosed, setIsClosed] = useState(false);

  const handleClose = (e) => {
    e.preventDefault();
    setIsClosed(true);
  };

  return (
    <>
      {isClosed === false && (
        <div
          className={`sticky top-0 left-0 w-full bg-gray-900 z-50 text-white ${
            isClosed ? "hidden" : ""
          }`}
        >
          <div className="flex justify-center items-center p-4">
            <p className="text-center flex-grow">
              Datacamp Donates has partnered up with MLSA Air University,{" "}
              <Link
                to="mlsa-and-datacamp"
                className="text-blue-400"
                onClick={() => setIsClosed(true)}
              >
                Learn More.
              </Link>
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-gray-500 hover:text-gray-700 transition-colors duration-200 ml-4 cursor-pointer"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              onClick={handleClose}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>
      )}
    </>
  );
};

export default Notification;
