import React from "react"
import { fastPageAnimation, photoAnim } from "../../../animation"
import { motion, useInView } from "framer-motion"
import { useRef, useState } from "react"
const Images = ({ img1, img2, img3, img4 }) => {
  const ImagesGridContainer = useRef(null)
  const isInView = useInView(ImagesGridContainer, { amount: 0.25 })

  const isInMobile = window.innerWidth < 500
  return (
    <motion.div
      ref={ImagesGridContainer}
      exit={"exit"}
      variants={!isInMobile ? fastPageAnimation : {}}
      initial={`hidden`}
      animate={isInView ? `show` : `hidden`}
      className="grid grid-cols-12 gap-x-3 gap-y-3 mb-10 mx-6"
    >
      <h1 className="text-gray-800 text-lg md:text-2xl lg:text-4xl my-5 col-span-12 justify-self-center">
        Event Gallery
      </h1>
      <ImageGalleryItem1 src={img1} />
      <ImageGalleryItem2 src={img2} />
      <ImageGalleryItem2 src={img3} />
      <ImageGalleryItem1 src={img4} />
      {/* PLACEHOLDER IMAGES */}
      {/* <motion.img
        variants={photoAnim}
        src={img1}
        className="object-cover col-span-12 lg:col-span-7 h-[23rem] sm:h-[21rem] md:h-[20rem] m-auto lg:h-[20rem] w-[55rem] border"
      /> */}
      {/* <div className="col-span-12 lg:col-span-7">
        <div className="w-full h-[80vh]  relative">
          <motion.img
            variants={photoAnim}
            className="w-full h-full object-cover"
            src={img4}
            alt=""
          />
        </div>
      </div>
      <div className="col-span-6 max-md:col-span-12 lg:col-span-5">
        <div className="w-full h-[80vh] max-lg:h-[70vh] max-md:h-[60vh] mx-auto relative">
          <motion.img
            variants={photoAnim}
            className="w-full h-full object-cover"
            src={img2}
            alt=""
          />
        </div>
      </div> */}
      {/* <motion.img
        variants={photoAnim}
        src={img2}
        className="object-cover col-span-6 lg:col-span-5 h-[16rem] sm:h-[21rem] md:h-[20rem] m-auto border lg:h-[20rem] w-[40rem]"
      /> */}
      {/* <div className="col-span-6 max-md:col-span-12 lg:col-span-5">
        <div className="w-full h-[80vh] mx-auto relative">
          <motion.img
            variants={photoAnim}
            className="w-full h-full object-cover"
            src={img3}
            alt=""
          />
        </div>
      </div>
      <div className="col-span-12 lg:col-span-7">
        <div className="w-full h-[80vh]  relative">
          <motion.img
            variants={photoAnim}
            className="w-full h-full object-cover"
            src={img4}
            alt=""
          />
        </div>
      </div> */}
      {/* <motion.img
        variants={photoAnim}

        src={img3}
        className="object-cover col-span-6 lg:col-span-5 h-[16rem] sm:h-[21rem] md:h-[20rem] m-auto border lg:h-[20rem] w-[40rem]"
      />
      <motion.img
        variants={photoAnim}
        
        src={img4}
        className="object-cover col-span-12 lg:col-span-7 h-[23rem] sm:h-[21rem] md:h-[20rem] m-auto lg:h-[20rem] w-[55rem] border"
      /> */}
    </motion.div>
  )
}

export default Images

function ImageGalleryItem1({ src }) {
  const isInMobile = window.innerWidth < 500

  return (
    <div className="col-span-12 lg:col-span-7">
      <div
        className="w-full 
       h-[70vh] 
       max-sm:h-[35vh] 
       max-md:h-[50vh]
       relative"
        
      >
        <motion.img
          variants={!isInMobile ? photoAnim : {}}
          className="w-full h-full object-cover rounded-md"
          src={src}
          alt=""
        />
      </div>
    </div>
  )
}

function ImageGalleryItem2({ src }) {
  const isInMobile = window.innerWidth < 500

  return (
    <div className="col-span-6 max-md:col-span-12 lg:col-span-5">
      <div
        className="w-full 
       h-[70vh]
       max-md:h-[100vh]
       max-sm:h-[70vh] 

       mx-auto relative"
      >
        <motion.img
          variants={!isInMobile ? photoAnim : {}}
          className="w-full h-full object-cover rounded-md"
          src={src}
          alt=""
        />
      </div>
    </div>
  )
}
