import { pageAnimation } from "../../animation"
import TeamCard from "./components/teamcard"
// import LeadCard from "./components/leadcard"
import { motion } from "framer-motion"
import AboutText from "./components/about"
import TeamsText from "./components/teamstext"
import { lazy, Suspense } from "react"
import { Helmet } from "react-helmet"
const LeadCard = lazy(() => import("./components/leadcard.js"))

export default function TeamsScreen() {
  return (
    <>
      <Helmet>
        <title>Teams | MLSA AU</title>
        <meta
          name="description"
          content="Discover the leadership and diverse teams of Microsoft Learn Student Ambassadors at Air University. Led by President Sameer Ahmed Siddiqui, MLSA AU embodies innovation, excellence, and a commitment to technological advancement."
        />
        <meta
          name="keywords"
          content="MLSA, Microsoft Learn Student Ambassadors, Air University, Leadership, Innovation, Technology, Sameer Ahmed Siddiqui, Student Community"
        />

        <meta
          property="og:description"
          content="Meet the visionary leaders and dynamic teams of MLSA AU at Air University. Under President Sameer Ahmed Siddiqui, we're driving technological innovation and academic excellence."
        />
        <meta
          property="og:image"
          content="URL to an image representing MLSA AU"
        />
        <meta property="og:url" content="URL of the MLSA AU page" />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Leadership and Innovation at MLSA AU - Air University"
        />
        <meta
          name="twitter:description"
          content="Explore the heart of innovation at Air University with MLSA AU. Guided by President Sameer Ahmed Siddiqui, our teams are shaping the future of technology."
        />
        <meta
          name="twitter:image"
          content="URL to an image representing MLSA AU"
        />
      </Helmet>
      <motion.div
        className="screen flex flex-col gap-10 justify-center"
        exit="exit"
        variants={pageAnimation}
        initial="hidden"
        animate="show"
      >
        <Suspense fallback={<Loading />}>
          <LeadCard />
        </Suspense>

        <AboutText />
        <TeamsText />
        <TeamCard />
      </motion.div>
    </>
  )
}

function Loading() {
  return (
    <div className="min-h-screen flex justify-center items-center">
      <div role="status" className="relative">
        <svg
          aria-hidden="true"
          className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentColor"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}
