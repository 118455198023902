import React from "react"
import { motion, useInView } from "framer-motion"
import { useRef, useState } from "react"
import { fade, scrollReveal } from "../../../animation"

const CodeAirTitle = () => {
  const teamsContainer = useRef(null)
  const isInView = useInView(teamsContainer, { amount: 0.25 })

  return (
    <div className="flex flex-col justify-center items-center">
      <h1 className="text-lg md:text-2xl lg:text-4xl">What is Code Air?</h1>
      <motion.p
        variants={scrollReveal}
        animate={"show"}
        initial="hidden"
        exit={"hidden"}
        className="w-[20rem] sm:w-[30rem] md:w-[40rem] lg:w-[50rem] text-center 
      my-5 text-sm md:text-md lg:text-lg text-slate-600"
      >
        CodeAir is an annual coding competition as well as our Flagship event
        held at Air University by Microsoft Learn Student Ambassadors, Air
        University. It features the best programmers not only from Air
        University but also from other universities competing with each other
        via demonstrating their coding proficiency to win exciting swags and
        prizes! What makes CodeAir superior to other coding competitions is the
        fact that students get to network with industry professionals, test and
        polish their coding skills so that they can upskill themselves.
      </motion.p>
    </div>
  )
}

export default CodeAirTitle
