import { pageAnimation } from "../../animation"
import { motion } from "framer-motion"
import Images from "./components/Images"
import Registeration from "./components/Registeration"
// import Title from "./components/Title"
// import RegisterationForm from "./components/registration-form"
import Logo from "./components/Logo"
import CodeAirTitle from "./components/Title"
import { Helmet } from "react-helmet"

export default function CodeAirScreen() {
  const imagearr = [
    "https://iili.io/Ja7h6la.jpg",
    "https://iili.io/Ja7hZDN.jpg",
    "https://iili.io/Ja7c842.jpg",
    "https://iili.io/Ja7j2R4.jpg",
  ]
  return (
    <>
      <Helmet>
        <title>
         Code Air | MLSA AU
        </title>
        <meta
          name="description"
          content="Join Code Air 2023, the annual flagship coding competition hosted by Microsoft Learn Student Ambassadors at Air University. Network with industry professionals, showcase your coding skills, and compete for exciting prizes!"
        />
        <meta
          name="keywords"
          content="Code Air, Coding Competition, Air University, Microsoft Learn Student Ambassadors, Programming Contest, Tech Events"
        />

        <meta
          property="og:description"
          content="Experience the thrill of Code Air 2023 - Air University's premier coding competition. Connect with experts, compete with top programmers, and win amazing prizes!"
        />
        <meta
          property="og:image"
          content="URL to an engaging image from the event"
        />
        <meta property="og:url" content="URL of the page" />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Code Air 2023 - Premier Coding Event by MLSA AU"
        />
        <meta
          name="twitter:description"
          content="Dive into Code Air 2023 at Air University. A top-tier coding contest by Microsoft Learn Student Ambassadors. Network, compete, and win!"
        />
        <meta
          name="twitter:image"
          content="URL to an engaging image from the event"
        />
      </Helmet>
      <motion.div
        exit="exit"
        variants={pageAnimation}
        initial="hidden"
        animate="show"
        className="bg-white screen h-max w-full"
      >
        <Logo />
        <CodeAirTitle />
        <Images
          img1={imagearr[0]}
          img2={imagearr[1]}
          img3={imagearr[2]}
          img4={imagearr[3]}
        />
        <Registeration />
        {/* <RegisterationForm /> */}
      </motion.div>
      
    </>
  )
}
