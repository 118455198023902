// import React from "react"
// const DataCampLogo = () => {
//   return (
//     <div className="bg-blue h-36 sm:h-48 md:h-64 lg:h-80 flex items-center justify-center mb-5 w-full">
//       <a href="https://sl.datacamp.com/t/100925/sc/d542b1d2-957d-4fbc-bcca-64515855ade8/NB2HI4DTHIXS653XO4XGIYLUMFRWC3LQFZRW63JPMRXW4YLUMVZT65LUNVPXG33VOJRWKPLEN5XGC5DFOMTHK5DNL5WWKZDJOVWT24DBOJ2G4ZLSEZ2XI3K7MNQW24DBNFTW4PJQGAYDAMBQL4YS22LOORSXE3TBNRPTELLOMFPTGLLHNRXWEYLML42C23TBL42S23TBL43C24TFOF2WS4TFNVSW45DTL43S2ZDPL44C24DUNZZC24DOL44S23TBL4YTALLOMFPTCMJNNRXWO3Y=/GFTGCNTGGVSDCZJVMI3WMNLCGM3WGNJZG5QWKNTCGVRTSMDFGY4TCYRUGM3GEZBT/www-datacamp-com-donates">
//         <img
//           src={logo}
//           alt=""
//           className="h-32 max-md:h-32 max-sm:h-20 object-cover"
//         />
//       </a>
//     </div>
//   )
// }

// export default DataCampLogo


import React from "react"

const DataCampLogo = () => {
  const logo = "https://onedrive.live.com/embed?resid=FD481C18FAE802BC%212348&authkey=%21ABB1YDvAoQQRci0&width=1630&height=450";

  return (
    <div className="bg-gray-900 h-36 sm:h-48 md:h-64 lg:h-80 flex items-center justify-center mb-5 w-full">
      <a href="https://sl.datacamp.com/t/100925/sc/d542b1d2-957d-4fbc-bcca-64515855ade8/NB2HI4DTHIXS653XO4XGIYLUMFRWC3LQFZRW63JPMRXW4YLUMVZT65LUNVPXG33VOJRWKPLEN5XGC5DFOMTHK5DNL5WWKZDJOVWT24DBOJ2G4ZLSEZ2XI3K7MNQW24DBNFTW4PJQGAYDAMBQL4YS22LOORSXE3TBNRPTELLOMFPTGLLHNRXWEYLML42C23TBL42S23TBL43C24TFOF2WS4TFNVSW45DTL43S2ZDPL44C24DUNZZC24DOL44S23TBL4YTALLOMFPTCMJNNRXWO3Y=/GFTGCNTGGVSDCZJVMI3WMNLCGM3WGNJZG5QWKNTCGVRTSMDFGY4TCYRUGM3GEZBT/www-datacamp-com-donates">
      <img
        src={logo}
        alt=""
        className="h-32 max-md:h-32 max-sm:h-20 object-cover "
      />
      </a>
    </div>
  )
}

export default DataCampLogo
