import React from 'react'

const Registeration = () => {
  return (
    <div className="flex items-center my-16">
        <div className="w-[0.35rem] sm:w-2 bg-black h-[4.5rem] sm:h-[8.5rem] lg:h-[10.5rem] mx-3"></div>
        <div className="bg-blue text-white h-24 sm:h-40 lg:h-48 flex flex-col justify-center items-center rounded-lg w-full shadow-2xl">
          <span className="text-md sm:text-2xl md:text-3xl">Get started with your license</span>
          <div className="mt-4">
            <a
              href="https://forms.gle/crTyCvAmkErTPFWM9"
            >
              <button className="bg-dark-blue rounded-md px-12 py-1.5">
                Register Here
              </button>
            </a>
          </div>
        </div>
        <div className="w-[0.35rem] sm:w-2 bg-black h-[4.5rem] sm:h-[8.5rem] lg:h-[10.5rem] mx-3"></div>
    </div>
  )
}

export default Registeration