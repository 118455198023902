import { fade, scrollReveal } from "../../../animation"
import { motion, useInView } from "framer-motion"
import { useRef, useState } from "react"

function TeamsText() {
  const teamsContainer = useRef(null)
  const isInView = useInView(teamsContainer, { amount: 0.25 })
  return (
    <>
      <h1 className="max-lg:-translate-y-[20%] text-center text-4xl text-gray-800 ">Our Teams (2023-2024)</h1>
      <div ref={teamsContainer} className="flex overflow-hidden justify-center items-center">
        <motion.p

            variants={scrollReveal}
            animate={isInView ? "show" : "hidden"}
            initial="hidden"

          className="w-[20rem] sm:w-[30rem] md:w-[40rem] lg:w-[50rem] text-center text-sm
       md:text-md lg:text-lg text-slate-600"
        >
          In the thriving academic milieu of Air University, the Microsoft Learn
          Student Ambassadors (MLSA) stands as a beacon of innovation,
          leadership, and technical prowess. Our diverse teams, each led by
          visionary individuals, collectively form the sinews of this vibrant
          community. From the captivating digital engagement orchestrated by our
          Social Media Management team to the eloquent narratives crafted by our
          Content Writers, and from the strategic foresight of our Documentation
          and Proposal unit to the creative brilliance radiating from our
          Graphic Designers, every faction contributes uniquely to our mosaic of
          success. The pulsating heart of our community, our Operations and
          Media teams, work tirelessly to immortalize our endeavors, while our
          Developers and Think Tank are the cerebral forces pushing the
          boundaries of technology and thought. Together, we are not just
          ambassadors; we are educators, innovators, and trailblazers, committed
          to illuminating the path of technological enlightenment and academic
          excellence for our peers. Join us, and be part of a legacy of
          excellence at MLSA Air University.
        </motion.p>
      </div>
    </>
  )
}

export default TeamsText
