import { Carousel } from "react-responsive-carousel"
import { pageAnimation, photoAnim, titleAnim } from "../../animation"
import { motion } from "framer-motion"
import RecentEvent from "./components/recent_events"
import Images from "../code-air/components/Images"
import { Helmet } from "react-helmet"

export default function EventsScreen() {
  const imagearr = [
    "https://iili.io/Ja7csQj.jpg",
    "https://iili.io/Ja7W7WX.jpg",
    "https://iili.io/Ja7cvEl.jpg",
    "https://iili.io/Ja7c4pe.jpg",
  ]

  return (
    <>
      <Helmet>
        <title>
          Events | MLSA AU
        </title>
        <meta
          name="description"
          content="Explore the world of open-source with 'Beyond the Source Code' at Air University. A deep dive into GitHub and open-source contributions with keynote speaker Arsalan Khattak."
        />
        <meta
          name="keywords"
          content="Open Source, GitHub, Air University, Arsalan Khattak, Developer Advocate, Tech Events, Coding, Programming"
        />

        <meta
          property="og:description"
          content="Join 'Beyond the Source Code' at Air University - an insightful event on open-source contributions featuring Arsalan Khattak, a renowned Developer Advocate."
        />
        <meta
          property="og:image"
          content="URL to an engaging image from the event"
        />
        <meta property="og:url" content="URL of the event page" />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Beyond the Source Code - Open-Source Insights at Air University"
        />
        <meta
          name="twitter:description"
          content="Dive into the open-source world with 'Beyond the Source Code' at Air University. Gain invaluable insights from Arsalan Khattak, a leading Developer Advocate."
        />
        <meta
          name="twitter:image"
          content="URL to an engaging image from the event"
        />
      </Helmet>

      <motion.div
        exit="exit"
        variants={pageAnimation}
        initial="hidden"
        animate="show"
        className=" screen"
      >
        <div>
          <Carousel
            infiniteLoop={true}
            showStatus={false}
            showThumbs={false}
            autoPlay={true}
          >
            <EventCarouselItem src="https://iili.io/Ja7ct4V.jpg" />
            <EventCarouselItem src="https://iili.io/Ja7cpv1.jpg" />
            <EventCarouselItem src="https://iili.io/Ja7cZEQ.jpg" />
          </Carousel>

          <RecentEvent />

          <Images
            img1={imagearr[0]}
            img2={imagearr[1]}
            img3={imagearr[2]}
            img4={imagearr[3]}
          />

          {/* <div className="grid grid-cols-12 gap-4 p-10">
          <div className="col-span-2 ">
            <motion.div
              variants={photoAnim}
              className=" bg-gray-200 h-40 rounded-lg mb-4"
            ></motion.div>
            <motion.div
              variants={photoAnim}
              className=" bg-purple-500 h-64 rounded-lg"
            ></motion.div>
          </div>
          <div className="col-span-4">
            <motion.div
              variants={photoAnim}
              className=" bg-blue-500 h-52 rounded-lg mb-3"
            ></motion.div>
            <motion.div
              variants={photoAnim}
              className=" bg-gray-200 h-52 rounded-lg"
            ></motion.div>
          </div>
          <div className="col-span-4">
            <motion.div
              variants={photoAnim}
              className=" bg-gray-200 h-52 rounded-lg mb-3"
            ></motion.div>
            <motion.div
              variants={photoAnim}
              className=" bg-slate-700 h-52 rounded-lg"
            ></motion.div>
          </div>
          <div className="col-span-2 ">
            <motion.div
              variants={photoAnim}
              className="bg-purple-500  h-40 rounded-lg mb-4"
            ></motion.div>
            <motion.div
              variants={photoAnim}
              className="bg-gray-200  h-64 rounded-lg"
            ></motion.div>
          </div>
        </div> */}
        </div>
      </motion.div>
    </>
  )
}

function EventCarouselItem({ src }) {
  return (
    <>
      <div className="w-full h-[80vh] max-md:h-[50vh] max-sm:h-[35vh] mx-auto relative">
        <img className="w-full h-full object-cover" src={src} alt="" />
      </div>
    </>
  )
}
