import React from "react"
import { motion, useInView } from "framer-motion"
import { useRef } from "react"
import {  scrollReveal } from "../../../animation"

const DataCampTitle = () => {
  const teamsContainer = useRef(null)
  const isInView = useInView(teamsContainer, { amount: 0.25 })

  return (
    <div className="flex flex-col justify-center items-center">
      <h1 className="text-lg md:text-2xl lg:text-4xl px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12">Datacamp Donates has partnered up with MLSA Air University</h1>
      <motion.p
        variants={scrollReveal}
        animate={"show"}
        initial="hidden"
        exit={"hidden"}
        className="w-[20rem] sm:w-[30rem] md:w-[40rem] lg:w-[50rem] text-center 
      my-5 text-sm md:text-md lg:text-lg text-slate-600"
      >
        With a deep dedication to provide high-quality data education to those who most need it, DataCamp Donates is motivated by a goal. In an effort to achieve this admirable goal, the organization grants access to DataCamp to eligible organizations with the express purpose of assisting job seekers, residents of underserved areas, students, and nonprofit research scientists. This initiative's ethos is based on the idea that everyone should have access to high-quality education since it is a fundamental human right rather than just a privilege.

The understanding that data literacy is a critical ability in today's information-driven environment is at the core of DataCamp Donates' purpose. Through the provision of complimentary access to DataCamp materials, the organization makes a noteworthy contribution to the fight against data illiteracy by guaranteeing that people from a variety of backgrounds can empower themselves with fundamental data science abilities. By doing this, they further the more general objective of democratizing data science, dismantling obstacles, and promoting diversity in the IT and data-driven sectors.

The beneficiaries of DataCamp Donates' charitable contributions include a diverse group of people with different types of difficulties. Individuals who are actively looking for work can use DataCamp's educational platform to expand their skill set, which will increase their marketability. People from underprivileged backgrounds who might otherwise encounter obstacles to schooling are given the chance to obtain important data skills, which could change their future prospects and end the cycle of limited options.

Register now for the chance to receive your 12-year license. Please bear in mind that MLSA Air University holds full responsibility for confirming your status before providing you with the licenses. In case of further clarity, we may conduct an interview or request additional details. However, rest assured that the information will always remain confidential and will not be shared with anyone else.

      </motion.p>
    </div>
  )
}

export default DataCampTitle
