import { ImFilePicture } from "react-icons/im";


function PaymentInfo() {
    return (
        <div className="bg-blue text-white md:w-[100%]  rounded-lg px-5 py-6">
            <h3 className="text-center text-3xl font-semibold">Payment Information</h3>
            <h5 className="text-start text-2xl mt-12 mb-3 px-2">Submit your payment at any ONE of the following account</h5>
            <textarea className="w-full h-32 rounded-md outline-none text-black py-5 px-2" type="textbox">Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae qui consequatur corrupti reiciendis at commodi totam nihil sunt atque dolor odit, ullam iusto deleniti quaerat quam laboriosam molestias quidem fugiat eaque facere officia.</textarea>
            <h5 className="text-2xl mb-3 px-2 mt-12">Payment Details</h5>
            <div className="flex items-center space-x-4 relative w-full">
                <div className="relative w-1/2">
                    <select className="text-black p-2 pr-8 border border-gray-300 rounded-md bg-white text-lg cursor-pointer w-full outline-none" >
                        <option disabled selected>
                            Bank Account
                        </option>
                        <option className="text-black hover:text-white" value="account1">Account 1</option>
                        <option className="text-black hover:text-white" value="account2">Account 2</option>
                        <option className="text-black hover:text-white" value="account3">Account 3</option>
                    </select>
                </div>
                <input type="text" className="p-2 border border-gray-300 rounded-md w-1/2 text-lg placeholder:text-black outline-none text-black" placeholder="Transaction ID" />
            </div>

            <div className="relative w-full my-3 cursor-pointer">
                <span className="inline-block py-2 px-4 bg-white w-full rounded-md text-gray-900 text-lg">
                    Transaction Receipt
                </span>
                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                    <ImFilePicture className="text-black h-5 w-5" />
                </div>
            </div>
            <div className="flex justify-center space-x-4 mt-16">
                <button className="bg-dark-blue px-12 rounded-lg shadow-xl py-2">Previous</button>
                <button className="bg-dark-blue px-12 rounded-lg shadow-xl py-2">Preview</button>
            </div>
        </div>
    );
}

export default PaymentInfo;