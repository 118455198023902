import React from "react";
import { motion, useInView } from "framer-motion";
import { useRef, useState } from "react";
import { scrollReveal, scrollRevealUp } from "../../../animation";
import { Link } from "react-router-dom";

const Codeair = () => {
  const aboutUs = useRef(null);
  const isInView = useInView(aboutUs, { amount: 0.3 });
  const isMobile = window.innerWidth < 500; //Add the width you want to check for here (now 768px)

  return (
    <div ref={aboutUs} className="overflow-hidden ">
      <motion.section
        variants={!isMobile ? scrollRevealUp : {}}
        animate={isInView ? "show" : "hidden"}
        initial="hidden"
        className="m-4 py-12 flex gap-5 flex-col lg:flex-row items-start justify-between px-4"
      >
        <div className="lg:w-1/2 order-2 lg:order-1 flex justify-center ">
          <img
            src="https://iili.io/Ja7h6la.jpg"
            alt="codeair"
            className="w-full object-cover rounded-md"
          />
        </div>

        <div className=" lg:w-1/2 order-1 lg:order-2 text-center lg:text-left">
          <h2 className="text-lg md:text-2xl lg:text-4xl text-gray-800 font-bold mb-4">
            What's CodeAir
          </h2>
          <p className="text-sm md:text-md lg:text-lg text-gray-600 mb-8">
            CodeAir is an extraordinary, much-anticipated annual coding
            competition, proudly standing as the flagship event meticulously
            organized by Microsoft Learn Student Ambassadors, Air University.
            This highly regarded competition serves as a beacon of excellence,
            attracting the most brilliant programmers not only from Air
            University but also from a diverse array of prestigious
            universities. These exceptionally talented individuals engage in a
            head-to-head battle of wits, putting their coding prowess to the
            test for a chance to claim exciting swags and prizes. What sets
            CodeAir apart, truly elevating it above other coding competitions,
            is the unparalleled opportunity it provides. Participants not only
            immerse themselves in a spirited coding challenge but also forge
            valuable connections with seasoned industry professionals. This
            unique networking avenue not only allows them to assess and refine
            their coding skills but also empowers them to embrace a continuous
            journey of upskilling and adaptation in the ever-evolving landscape
            of technology. The enduring impact of CodeAir extends far beyond the
            event itself, nurturing a culture of excellence and growth in the
            tech-savvy community.
          </p>
          <div className="max-lg:flex justify-center">
            <Link
              onClick={() => window.scrollTo(0, 0)}
              to={"/code-air"}
              className="bg-dark-blue  py-2 px-10 w-max my-7 flex items-center text-white"
            >
              See more
            </Link>
          </div>
        </div>
      </motion.section>
    </div>
  );
};

export default Codeair;
