// IMPORTS -
import { Routes, Route } from "react-router-dom"
import ContactUsScreen from "./screens/contact-us/contact-us"
import HomeScreen from "./screens/home/home"
import CodeAirScreen from "./screens/code-air/code-air"
import EventsScreen from "./screens/events/events"
import NotFoundScreen from "./screens/not-found/not-found"
import TeamsScreen from "./screens/teams/teams"
import Navbar from "./components/nav"
import Footer from "./components/footer"
import { Toaster } from "react-hot-toast"
import Notification from "../src/components/Hero-Test"
import WebAirReg from "./screens/web-air-reg/web-air-reg"
import DataCampScreen from "./screens/DataCamp/DataCamp"
import "./App.css"

function App() {

  
  return (
    //add routing
    <div>
      <Toaster position="top-right" />
      <Notification></Notification>
      <Navbar />
      
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/code-air" element={<CodeAirScreen />} />
          <Route path="/contact-us" element={<ContactUsScreen />} />       
          <Route path="/teams" element={<TeamsScreen />} />
          <Route path="/events" element={<EventsScreen />} />
          <Route path="*" element={<NotFoundScreen />} />
          <Route path="web-air-reg" element={<WebAirReg />} />
          <Route path="/mlsa-and-datacamp" element={<DataCampScreen />} />
        </Routes>

      <Footer />
    </div>
  )
}

export default App
